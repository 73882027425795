<template>
  <div class="flex gap-2 flex-col mx-auto w-full lg:max-w-[70%]">
    <div class="bg-white rounded-md shadow-sm border">
      <div class="row" v-if="loadingInformation">
        <div class="col-md-12">
          <shimmer :num="5"/>
        </div>
      </div>
      <form class="flex flex-col gap-3 p-3" v-else>
        <div class="flex flex-col gap-1">
          <label class="text-sm" style="margin-bottom: 2px !important;">Power Source:</label>
          <el-select filterable v-model="payload.power_source" placeholder="Select power source">
            <el-option :value="item.value" :label="item.label" v-for="(item,key) in powerSourceList"
                       :key="key">
              {{ item.label }}
            </el-option>
          </el-select>
          <p class="text-xs">Enabling this power source help determine the tariff to use when charging user power
            usage.</p>
        </div>
        <div class="flex flex-col gap-1">
          <label class="text-sm">Minimum recharge amount:</label>
          <el-input placeholder="" type="number" class="w-full" min="0"
                    v-model="payload.minimum_recharge_amount"
                    required></el-input>
        </div>
        <div class="mt-3">
          <el-checkbox v-model="payload.is_tariff_shown_to_customer" class="text-wrap flex items-center">
            Activate the feature to display the meter tariff on the customer dashboard.
          </el-checkbox>
        </div>
      </form>
    </div>
    <div>
      <el-button class="w-full lg:w-auto" type="primary" native-type="button" :disabled="loading"
                 v-if="loadingInformation === false" v-on:click="submit"
      >
        Update <i
          class="el-icon-loading ml-2" v-if="loading"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import shimmer from "../../../reusable/shimmer";
import {mapGetters} from "vuex"

export default {
  name: "Settings",
  components: {shimmer},
  data: function () {
    return {
      loadingInformation: false,
      bankVerified: '',
      loading: false,
      resolving_account: false,
      bank_resolve_error: false,
      powerSourceList: [
        {
          value: "phcn",
          label: "PHCN"
        },
        {
          value: "solar",
          label: "Solar Power"
        },
        {
          value: "generator",
          label: "Generator"
        },
      ],
      payload: {
        "power_source": '',
        "minimum_recharge_amount": 0,
        "is_tariff_shown_to_customer": false,
      },
    }
  },
  methods: {
    getMerchantMeterSetting: function () {
      this.loadingInformation = true
      this.$store.dispatch('meter/getMerchantSettings', {'merchant': this.GET_MERCHANT_INFO.code}).then((res) => {
        this.loadingInformation = false
        this.payload = {
          "power_source": res.data.power_source,
          "minimum_recharge_amount": res.data.minimum_recharge_amount,
          "is_tariff_shown_to_customer": res.data.is_tariff_shown_to_customer
        }
      }).catch(() => {
        this.loadingInformation = false
      })
    },
    submit: function () {
      this.loading = true
      this.$store.dispatch('meter/updateMerchantSettings', {
        'data': this.payload,
        'merchant': this.GET_MERCHANT_INFO.code
      })
          .then(() => {
            this.loading = false
            this.$toast.success({
              title: 'success',
              message: 'Setting updated successfully'
            })
          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    },
  },
  mounted() {
    this.getMerchantMeterSetting()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_MERCHANT_INFO: 'user/GET_MERCHANT_INFO'
    })
  }
}
</script>