<template>
  <div class="h-full py-6 px-[20px] bg-mainBG flex flex-col gap-4">
    <div class="flex flex-col lg:flex-row gap-4">
      <div class="h-full w-full lg:w-[50%]">
        <div class="h-full flex flex-col gap-4 lg:max-h-80">
          <div class="shadow-xs border rounded max-h-full h-full lg:max-h-64 p-6 bg-mainPurple">
            <div>
              <div class="flex align-start text-xl lg:text-2xl mb-[3px] text-white text-wrap ">Welcome back, <span
                  class="font-semibold ml-2">{{ walletInfo.merchant }}</span></div>
              <p class="mt-4 text-white">Your customers are waiting on you. What do you
                have in store for them.</p>
            </div>
            <div>
              <div class="flex flex-col">
                <div class="w-full">
                  <p class="text-white text-sm">Wallet Balance</p>
                  <p class="text-5xl font-semibold text-white">NGN {{ getAmount(walletInfo.amount) }}</p>
                </div>

              </div>
            </div>
          </div>
          <div
              class="w-full flex flex-col bg-white p-3 gap-4  lg:flex-row lg:justify-between items-start lg:items-center rounded">
            <h4 class="text-left font-medium">Wallet Withdrawal</h4>
            <el-button class="h-[40px] w-full lg:w-auto" type="primary" icon="el-icon-plus" size="mini"
                       v-on:click="onOpenWithdraw">REQUEST PAYOUT
            </el-button>
          </div>
        </div>

      </div>
      <div class="w-full lg:w-[50%]">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <OverviewCard v-for="item in overview" :key="item.name" :item="item"/>
        </div>
      </div>

    </div>
    <div class="flex flex-col lg:flex-row gap-4 w-full">
      <!--      <div class="w-full lg:w-1/2">-->
      <!--        <MeterGraph/>-->
      <!--      </div>-->
      <div class="w-full lg:w-1/2 max-h-[250px]">
        <TableComponent :results="meterUptimesData.results" :total_pages="1" :columns="columns"
                        tableHeader="Meter Uptime" @onChangePageNumber="changePageNumber"
                        :show-page-number="false"/>
      </div>
    </div>
    <Modal v-if="openWithdraw" :width="600"
           v-bind:title="`Initiate payout`"
           @handleClose="openWithdraw = false">
      <div class="w-full" slot="body">
        <Withdraw @onSuccess="onSuccess" @close="onClose"/>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
  </div>
</template>

<script>

import OverviewCard from "./OverviewCard";
import TableComponent from "../../../components/Table"
import Modal from "../../../components/Modal";
import {mapGetters} from "vuex";
import Withdraw from "../MeterPortal/components/Withdraw"
import {formatAmount} from "../../../utils";
import moment from "moment";

export default {
  name: "Overview",
  components: {
    OverviewCard,
    Modal,
    Withdraw,
    TableComponent
  },
  data: function () {
    return {
      overview: [
        {
          name: "Meters",
          total: 0,
          icon: "meter",
          type: 'total_meter',
          path: "meter-dashboard"
        },
        {
          name: "Customers",
          total: 0,
          icon: "user",
          type: 'total_customer',
          path: "meter-customer"
        },
        {
          name: "Tariffs",
          total: 0,
          icon: "package",
          type: 'total_tariff',
          path: "meter-tariff"
        },
      ],
      walletInfo: {
        merchant: '',
        amount: 0
      },
      openWithdraw: false,
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'field',
          customClass: 'm-w-[200px] lg:m-w-min-w-full'
        },
        {
          name: 'unit',
          label: 'Units',
          type: 'field',
          customClass: 'm-w-[200px] lg:m-w-min-w-full'
        },
        {
          name: 'last_seen',
          label: 'Last Seen',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (item) => {
            const {last_seen} = item
            if (!last_seen) return ""
            return moment(last_seen).format('YYYY-MM-DD hh:mm a')
          }
        },
      ],
      meterUptimesData: {
        results: [],
        uptimes: []
      }
    }
  },
  methods: {
    onPaginate: function (pageNumber) {
      const pageSize = 4
      if (pageNumber < 1) {
        return
      }

      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = startIndex + 4;

      this.meterUptimesData.results = this.meterUptimesData.uptimes.slice(startIndex, endIndex);

    },
    getMerchantWalletInformation: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMerchantWallet', {merchant: merchant}).then(resp => {
        this.walletInfo = resp.data
      })
    },
    getMerchantOverview: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMerchantOverview', {merchant: merchant}).then(resp => {
        const over = resp.data
        this.overview.forEach((item) => {
          item.total = over[item.type]
        })
      })
    },
    getMerchantMeterUptime: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMeterUptime', {merchant: merchant}).then(resp => {
        this.meterUptimesData.uptimes = resp.data
        setTimeout(() => {
          this.onPaginate(1)
        }, 5)
      })
    },
    getAmount: function (amount) {
      return formatAmount(amount)
    },
    onOpenWithdraw: function () {
      this.openWithdraw = !this.openWithdraw
    },
    onSuccess: function () {
      this.openWithdraw = false
      this.getMerchantWalletInformation()
    },
    onClose: function () {
      this.openWithdraw = false
    },
    changePageNumber: (pageNumber) => {
      this.onPaginate(pageNumber)
    },

  },
  mounted() {
    this.getMerchantWalletInformation()
    this.getMerchantOverview()
    this.getMerchantMeterUptime()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_METERS: 'meter/GET_METERS'
    })
  }

}
</script>

<style scoped>

</style>