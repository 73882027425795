<template>
  <section class="mt-6 w-full">
    <div class="">
      <TableComponent :results="results" :total_pages="total_pages" :columns="columns"/>
    </div>
  </section>
</template>

<script>
import TableComponent from "../../../../../components/Table"
import {mapGetters} from "vuex";
import moment from "moment";
import {formatAmount} from "../../../../../utils";

export default {
  name: "MeterPayment",
  components: {TableComponent},
  data: function () {
    return {
      columns: [
        {
          name: 'date',
          label: 'Date / Time',
          type: 'custom',
          custom: (row) => {
            if (!row.created_at) return ""
            return `${this.dateFormat(row.created_at)}`
          }
        },
        {
          name: 'type',
          label: 'Type',
          type: 'custom',
          custom: (row) => {
            if (!row.type) return ""
            if (row.type === 'meter_recharge') {
              return 'Meter Recharge'
            } else {
              return 'Wallet Top-up'
            }
          }
        },
        {
          name: 'status',
          label: 'Status',
          type: 'customOption',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          options: [
            {
              label: 'PAID',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'paid'
            },
            {
              label: 'SUCCESS',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'success'
            },
            {
              label: 'UNPAID',
              customClass: 'bg-red-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'unpaid'
            },
            {
              label: 'PENDING',
              customClass: 'bg-yellow-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'pending'
            },
            {
              label: 'FAILED',
              customClass: 'bg-red-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'failed'
            },
            {
              label: 'REVERSED',
              customClass: 'bg-red-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'reversed'
            },
          ]
        },
        {
          name: 'amount',
          label: 'Amount / Unit',
          type: 'custom',
          custom: (row) => {
            if (!row.type) return ""
            if (row.type === 'meter_recharge') {
              return `${row.amount} units`
            } else {
              return `₦${formatAmount(row.amount)}`
            }
          }
        },
      ],
      results: [],
      total_pages: 1,
      limit: 10,
    }
  },
  methods: {
    dateFormat: function (dt_created) {

      if (!dt_created) {
        return ''
      }
      return moment(dt_created).format('YYYY-MM-DD hh:mm a')
    },
    getTransactions: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_transaction/getMeterTransactions', {
        meter_code: this.$route.params.code,
        merchant: merchant,
        data: `?limit=${this.limit}`
      }).then(resp => {
        this.results = resp.data.results
        this.total_pages = resp.data.total
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    }),
  },
  mounted() {
    this.getTransactions()
  }
}
</script>

<style scoped>

</style>