<template>
  <section>
    <div class="w-full flex justify-between gap-5 flex-col-reverse md:flex-row my-3 h-auto">
      <div class="rounded-lg shadow-sm p-4 bg-white w-full relative md:w-2/3 flex flex-col justify-between">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div class="">
            <p class="text-mainYellow font-medium text-[0.9rem] mb-[5px] tracking-wide text-start">
              LOAD (WATTS)
            </p>
            <p class="text-black font-bold text-start">{{ reading.power }}</p>
          </div>
          <div class="">
            <p class="text-mainYellow text-start font-medium mb-[5px] text-[0.9rem] tracking-wide text-start">
              UNITS REMAINING

            </p>
            <p class="text-black font-bold text-start">{{ reading.units_remaining }}</p>
          </div>
          <div class="">
            <p class="text-mainYellow font-medium mb-[5px] text-[0.9rem] tracking-wide">
              ENERGY CONSUMED(KWHr)

            </p>
            <p class="text-black font-bold">{{ reading.energy_consumed }}</p>
          </div>
          <div class="">
            <p class="text-mainYellow font-medium mb-[5px] text-[0.9rem] tracking-wide">
              CURRENT TARIFF PER(KWHr)

            </p>
            <p class="text-black font-bold">{{ reading.tariff }}</p>
          </div>
        </div>

      </div>

      <div class="rounded-lg shadow-sm p-4 bg-white w-full md:w-1/3">
        <p class="text-mainYellow font-medium text-[0.9rem] tracking-wide">
          STATUS
        </p>

        <p class="text-[0.65rem] my-2">
          Your meter is currently
          <span class="font-black" v-if="isOn">ON</span>
          <span class="font-black" v-else>OFF</span>
        </p>

        <div class="flex items-center justify-between w-full bg-mainBG rounded-lg p-2 relative mt-4 text-[0.85rem]">
          <button
              class="flex-1 py-1 rounded-lg transition-colors duration-300 ease-in-out focus:outline-none"
              :class="isOn ? 'bg-green-500 text-white' : 'bg-transparent'"
          >
            ON
          </button>
          <button
              class="flex-1 py-1 rounded-lg text-mainBorder transition-colors duration-300 ease-in-out focus:outline-none"
              :class="!isOn ? 'bg-red-500 text-white' : 'bg-transparent'"
          >
            OFF
          </button>
        </div>
        <div class="w-full mt-3">
          <button @click="switchMeterRequest"
                  class="w-full py-1 rounded-lg bg-mainPurple text-white py-2 transition-colors duration-300 ease-in-out focus:outline-none"

          >
            {{ isOn ? "Turn Off" : "Turn On" }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {errorFormat} from "../../../../../utils";

export default {
  name: "MeterCardReading",
  data: function () {
    return {
      isOn: false,
      interval: null,
      reading: {
        'units_remaining': 0,
        'energy_consumed': 0,
        "power": 0,
        "tamper_detection": 0,
        "switch_status": 0,
        tariff: 0
      },
    }
  },
  methods: {
    onSwitch: function () {
      this.isOn = !this.isOn
    },
    getMeterReading: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_reading/getLatestReading', {
        meter_code: this.$route.params.code,
        merchant: merchant
      }).then(resp => {
        this.reading = resp.data
        this.isOn = this.reading.switch_status
      })
    },
    switchMeterRequest: function () {
      const merchant = this.GET_USER.merchant.code
      const switchStatus = this.isOn ? "Turn Off" : "Turn On"
      const switchState = this.isOn ? "OFF" : "ON"
      this.$confirm(`Please confirm your request to ${switchStatus} this
          meter. Note that the meter user can override
          this operation from their dashboard. To prevent this, you will need to disable the meter switch request action
          on the meter.`, 'Switch Request Confirmation', {
        confirmButtonText: `Yes, ${switchStatus}`,
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('meter/meterSwitchRequest', {
              merchant: merchant, code: this.$route.params.code, data: {
                status: switchState
              }
            }).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'Meter switch request has been schedule. This can take couple of secs.'
              })
            }).catch(er => {

              instance.confirmButtonLoading = false;
              instance.confirmButtonText = `Yes, ${switchStatus}`;
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
  },
  mounted() {
    this.getMeterReading()
    this.interval = setInterval(function () {
      this.getMeterReading();
    }.bind(this), 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    }),
  }
}
</script>

<style scoped>

</style>