import { render, staticRenderFns } from "./IndexView.vue?vue&type=template&id=698b2fb7&scoped=true&"
import script from "./IndexView.vue?vue&type=script&lang=js&"
export * from "./IndexView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698b2fb7",
  null
  
)

export default component.exports