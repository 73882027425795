<template>
  <div class="h-screen overflow-hidden bg-mainBG">
    <nav-bar/>
    <div class="h-screen overflow-y-auto">
      <div class="w-full lg:w-[50%] mx-auto px-[20px] lg:px-0">
        <div class="py-8">
          <h3 class="text-lg font-semibold text-center">What are you interested in today.</h3>
        </div>
        <div class="flex flex-wrap justify-center items-center gap-6">
          <service-card v-for="service in services" :key="service.name" :service="service" :features="features()"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ServiceCard from "./ServiceCard";
import NavBar from "./NavBar";
import Meter from "../../../assets/meter/electric-meter.png";
import Tranportation from "../../../assets/transportation/public-transport.png";
import {mapGetters} from "vuex";

export default {
  name: "IndexView",
  components: {NavBar, ServiceCard},
  data: function () {
    return {
      services: [
        {
          name: 'Meter Management',
          image: Meter,
          slug: 'meter',
          path: 'meter-overview'
        },
        {
          name: 'Device Management',
          image: Tranportation,
          slug: 'transport',
          path: 'dashboard'
        },
      ]
    }
  },
  methods: {
    features: function () {
      const {merchant} = this.GET_USER
      const {feature_set} = merchant
      return feature_set || []

    },
    getProfile: function () {
      this.loadingInformation = true
      this.$store.dispatch('user/GET_ACCOUNT', {'id': this.GET_USER.id}).then(() => {
        this.loadingInformation = false
      }).catch(() => {
        this.loadingInformation = false
      })
    },
  },
  mounted() {
    this.getProfile()
  },
  computed: {
    ...mapGetters({
      SIDE_BAR: 'general/getSideBar',
      GET_USER: 'user/GET_USER'
    }),

  }
}
</script>

<style scoped>

</style>