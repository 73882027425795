import ApiService from "./../services/api.service";
// state
const state = {
    tariffs: {results: [], total_pages: 0},
    meters: {results: [], total_pages: 0},
    actions: {results: [], total_pages: 0},
    total_pages: 1,
    meter: {}
}
// general getter
const getters = {
    GET_TARIFFS(state) {
        return state.tariffs
    },
    GET_METERS(state) {
        return state.meters
    },
    GET_ACTIONS(state) {
        return state.actions
    },
    GET_SINGLE_METER(state) {
        return state.meter
    },
}
// action
const actions = {
    getTariffs(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/tariffs/?${payload.data}`,)
                .then(response => {
                    context.commit('UPDATE_TARIFFS', {
                        results: response.data.results,
                        total_pages: response.data.total
                    })
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/meters/tariffs/`, payload.data)
                .then(response => {
                    context.commit('ADD_TARIFF', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.patch(`site/merchant/${payload.merchant}/meters/tariffs/${payload.code}/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_SINGLE_TARIFF', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete(`site/merchant/${payload.merchant}/meters/tariffs/${payload.code}/`)
                .then(response => {
                    context.commit('DELETE_TARIFF', payload)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMeters(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/?${payload.data}`,)
                .then(response => {
                    context.commit('UPDATE_METERS', {
                        results: response.data.results,
                        total_pages: response.data.total
                    })
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMeter(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.code}/`,)
                .then(response => {
                    context.commit('UPDATE_METER', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createMeter(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/meters/`, payload.data)
                .then(response => {
                    context.commit('ADD_METER', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateMeter(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.patch(`site/merchant/${payload.merchant}/meters/${payload.code}/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_SINGLE_METER', response.data)
                    context.commit('UPDATE_METER', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteMeter(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete(`site/merchant/${payload.merchant}/meters/${payload.code}/`)
                .then(response => {
                    context.commit('DELETE_METER', payload)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    meterFactoryResetRequest(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/meters/${payload.code}/request/action/`, payload.data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    meterSwitchRequest(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/meters/${payload.code}/switch/`, payload.data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMeterWallet(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/wallet/`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMerchantWallet(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/account/wallet/`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMerchantOverview(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/overview/`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMerchantSettings(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/settings/`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateMerchantSettings(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.patch(`site/merchant/${payload.merchant}/meters/settings/`, payload.data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMeterActionRequest(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/actions/`,)
                .then(response => {
                    context.commit('UPDATE_ACTIONS', {
                        results: response.data.results,
                        total_pages: response.data.total
                    })
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMeterUptime(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/uptime/`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

// mutation
const mutations = {
    UPDATE_ACTIONS(state, payload) {
        state.actions.results = payload.results
        state.actions.total_pages = payload.total_pages
    },
    UPDATE_TARIFFS(state, payload) {
        state.tariffs.results = payload.results
        state.tariffs.total_pages = payload.total_pages
    },
    UPDATE_SINGLE_TARIFF(state, payload) {
        const tariffIndex = state.tariffs.results.findIndex(item => item.code === payload.code)
        const resultCopy = JSON.parse(JSON.stringify(state.tariffs))
        if (tariffIndex > -1) {
            resultCopy.results[tariffIndex] = payload
            state.tariffs = resultCopy
        }
    },
    ADD_TARIFF(state, payload) {
        const resultCopy = JSON.parse(JSON.stringify(state.tariffs))
        resultCopy.results.unshift(payload)
        state.tariffs = resultCopy
    },
    DELETE_TARIFF(state, payload) {
        const results = JSON.parse(JSON.stringify(state.tariffs))
        const resultCopy = results.results.filter(item => item.code !== payload.code)
        state.tariffs = {
            results: resultCopy,
            total_pages: state.total_pages
        }
    },
    UPDATE_METERS(state, payload) {
        state.meters.results = payload.results
        state.meters.total_pages = payload.total_pages
    },
    UPDATE_METER(state, payload) {
        console.log(payload)
        state.meter = payload
    },
    UPDATE_SINGLE_METER(state, payload) {
        const meterIndex = state.meters.results.findIndex(item => item.code === payload.code)
        const resultCopy = JSON.parse(JSON.stringify(state.meters))
        if (meterIndex > -1) {
            resultCopy.results[meterIndex] = payload
            state.meters = resultCopy
        }
    },
    ADD_METER(state, payload) {
        const resultCopy = JSON.parse(JSON.stringify(state.meters))
        resultCopy.results.unshift(payload)
        state.meters = resultCopy
    },
    DELETE_METER(state, payload) {
        const results = JSON.parse(JSON.stringify(state.meters))
        const resultCopy = results.results.filter(item => item.code !== payload.code)
        state.meters = {
            results: resultCopy,
            total_pages: state.total_pages
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

