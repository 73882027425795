<template>
  <div class="py-6 px-[20px] bg-mainBG">
    <div class="block lg:flex lg:justify-between gap-2">
      <div class="w-full flex gap-4">
        <el-button class="float-end h-[40px]" type="primary" icon="el-icon-plus" size="mini" v-on:click="addCustomer"
                   :disabled="loading">ADD
          CUSTOMER
        </el-button>
        <el-input placeholder="Search " class="justify-items-start w-full lg:w-3/12 mb-2 lg:mb-0"
                  v-model="payload.search" @input="onSearch"></el-input>
      </div>
      <div class="">
        <el-select v-model="payload.filter" filterable clearable placeholder="Filter"
                   class="justify-items-end w-full" v-on:change="onSearch">
          <el-option
              v-for="(item,key) in filterList"
              :key="key"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="w-full">
      <TableComponent :results="GET_CUSTOMERS.results"
                      :total_pages="GET_CUSTOMERS.total_pages"
                      :columns="columns"
                      :action-list="actionList"
                      @handleActionCommand="handleActionCommand"
                      :hide-footer="false"
                      @onResultChange="changeResultNumber"
                      @onChangePageNumber="changePageNumber"
                      @onEdit="onEdit"
                      @onCheckAll="onCheckAll"
                      @onSingleCheck="onSingleCheck"
                      @onDelete="onDelete"/>
    </div>
    <Modal v-if="onOpenCustomerForm" :width="600"
           v-bind:title="currentCustomer.code ? 'Update Customer' : 'Create Customer'"
           @handleClose="onOpenCustomerForm = false">
      <div class="w-full" slot="body">
        <form v-on:submit.prevent="submitForm">
          <div class="w-full">
            <div class="mt-3">
              <label class="mr-sm-2 label">First Name</label>
              <el-input placeholder="First Name" type="text" class="w-100"
                        v-model="currentCustomer.first_name" required></el-input>
            </div>
            <div class=" mt-3">
              <label class="mr-sm-2 label">Last Name</label>
              <el-input placeholder="Last Name" type="text" required class="w-100"
                        v-model="currentCustomer.last_name"></el-input>
            </div>
            <div class=" mt-3">
              <label class="mr-sm-2 label">Email</label>
              <el-input placeholder="Email" type="email" required class="w-100"
                        v-model="currentCustomer.email"></el-input>
            </div>
            <div class=" mt-3">
              <label class="mr-sm-2 label">Phone Number</label>
              <el-input placeholder="Phone Number" type="text" required class="w-100"
                        v-model="currentCustomer.phone_number"></el-input>
            </div>
            <div class=" mt-3">
              <label class="mr-sm-2 label">Status</label>
              <el-select v-model="currentCustomer.status" filterable clearable placeholder="Select Status"
                         class="justify-items-end w-full">
                <el-option
                    v-for="(item,key) in customerStatusList"
                    :key="key"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="">
              <el-button type="primary" native-type="submit" class="mt-4"
                         :disabled="onSubmitLoading">Submit<i
                  class="el-icon-loading ml-2" v-if="onSubmitLoading"></i>
              </el-button>
            </div>
          </div>
        </form>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../../components/Modal";
import TableComponent from "../../../components/Table"
import {mapGetters} from "vuex";
import {errorFormat} from "../../../utils";
import moment from "moment";

export default {
  name: "dashboard",
  components: {Modal, TableComponent},
  data: function () {
    return {
      onOpenCustomerForm: false,
      loading: true,
      onSubmitLoading: false,
      limit: 10,
      message: 'No data available',
      currentPage: 1,
      searchName: '',
      payload: {
        filter: '',
        page: 1,
        search: ''
      },
      filterList: [
        {
          name: 'Active',
          value: 'active'
        },
        {
          name: 'Inactive',
          value: 'inactive'
        },
      ],
      customerStatusList: [
        {
          name: 'Active',
          value: 'active'
        },
        {
          name: 'Inactive',
          value: 'inactive'
        },
      ],
      currentCustomer: {
        first_name: "",
        last_name: "",
        email: "",
        status: "",
        phone_number: ""
      },
      columns: [
        {
          'name': 'checked',
          'label': '#',
          'type': 'checkbox',
          customClass: 'lg:max-w-[20px] w-[20px] min-w-[20px]',
        },
        {
          name: 'code',
          label: 'CUSTOMER ID',
          type: 'field',
          customClass: 'lg:m-w-min-w-full'
        },
        {
          name: 'first_name',
          label: 'FIRST NAME',
          type: 'field',
          customClass: ''
        },
        {
          name: 'last_name',
          label: 'LAST NAME',
          type: 'field',
          customClass: ''
        },
        {
          name: 'email',
          label: 'EMAIL',
          type: 'field',
          customClass: ''
        },
        {
          name: 'status',
          label: 'STATUS',
          type: 'customOption',
          customClass: '',

          options: [
            {
              label: 'Active',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'active'
            },
            {
              label: 'Inactive',
              customClass: 'bg-[#FE9431] px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'inactive'
            },
          ]
        },
        {
          name: 'invite_status',
          label: 'Invitation Status',
          type: 'customOption',
          customClass: '',
          options: [
            {
              label: 'Accepted',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'accepted'
            },
            {
              label: 'Waiting',
              customClass: 'bg-[#FE9431] px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'waiting'
            },
            {
              label: 'Not Invited',
              customClass: 'bg-[#FE9431] px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: 'not_invited'
            },
          ]
        },
        {
          name: 'date_created',
          label: 'DATE CREATED',
          type: 'custom',
          customClass: '',
          custom: function (item) {
            if (!item.created_at) return ""
            return moment(item.created_at).format('YYYY-MM-DD')
          }
        },
        {
          name: '',
          label: '',
          type: 'action',
          customClass: '',
          methodList: [
            {
              'icon': 'edit',
              title: 'Edit',
              css: 'bg-gray-200 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onEdit(item)
              },
              enabled: () => {
                return true
              }
            },
            {
              'icon': 'delete',
              title: 'Delete',
              css: 'bg-red-500 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onDelete(item)
              },
              enabled: () => {
                return true
              }
            },
          ],
        },
      ],
      actionList: [
        {
          'icon': 'add',
          title: 'Send Invitation',
          command: 'send_invitation',
        },
        {
          'icon': 'add',
          title: 'Copy Invitation Link',
          command: 'copy_invite_link',
        },

      ],
    }
  },
  methods: {
    addCustomer: function () {
      this.currentCustomer = {}
      this.onOpenCustomerForm = true
    },
    onEdit: function (customer) {
      this.currentCustomer = JSON.parse(JSON.stringify(customer))
      this.onOpenCustomerForm = true
    },
    submitForm: function () {
      const {code} = this.currentCustomer
      const merchant = this.GET_USER.merchant.code
      this.onSubmitLoading = true
      if (code) {
        this.$store.dispatch('customers/updateCustomer', {
          data: this.currentCustomer,
          code: code,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenCustomerForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Customer updated successfully"
          })
        }).catch(err => {
          const message = errorFormat({error: err})
          this.onSubmitLoading = false
          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      } else {
        this.$store.dispatch('customers/createCustomer', {
          data: this.currentCustomer,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenCustomerForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Customer created successfully"
          })
        }).catch(err => {
          const message = errorFormat({error: err})
          this.onSubmitLoading = false
          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      }

    },
    changePageNumber: function (value) {
      this.currentPage = value
      let page = this.computeParam()
      this.onMakeRequest(page)
    },
    computeParam: function () {
      let page = `page=${this.currentPage}&page_size=${this.limit}`
      if (this.payload.search !== '') {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&status=${this.payload.filter}`
      }
      return page
    },
    onMakeRequest: function (page) {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('customers/getCustomers', {data: page, merchant: merchant}).then(() => {
        this.loading = false
      })
    },
    changeResultNumber: function (limit) {
      this.limit = limit
      let page = this.computeParam()
      this.onMakeRequest(page)
    },
    onSearch: function () {
      this.currentPage = 1
      let page = this.computeParam()
      this.onMakeRequest(page)
    },
    getCustomers: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      const param = this.computeParam()
      this.$store.dispatch('customers/getCustomers', {
        data: param,
        merchant: merchant
      }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onDelete: function (customer) {
      const merchant = this.GET_USER.merchant.code
      this.$confirm('Do you want to delete this customer', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('customers/deleteCustomer', {merchant: merchant, code: customer.code}).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'customer deleted successfully'
              })
            }).catch(er => {

              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
    onSendInvitation: function () {
      const merchant = this.GET_USER.merchant.code
      const customers = this.GET_CUSTOMERS.results
      const codes = customers.filter(item => ['not_invited', "waiting"].includes(item.invite_status) && item.checked).map(item => item.code)
      if (codes.length === 0) {
        this.$toast.warn({
          title: 'warning',
          message: 'Please select at least one customer to send invitation'
        })
        return
      }
      this.$confirm(`Kindly confirm you want to send portal invitation to ${codes.length} customers`, 'Invitation Confirmation', {
        confirmButtonText: 'Send Invitation',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('customers/sendPortalInvitation', {
              merchant: merchant,
              data: {
                codes: codes
              }
            }).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Send Invitation';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'Customer invitation sent successfully.'
              })
              this.getCustomers()
            }).catch(er => {

              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Send Invitation';
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
    onSingleCheck: function (item) {
      console.log(item)
    },
    onCheckAll: function () {
      console.log("am clicked")
    },
    handleActionCommand: function (command) {
      if (command === 'send_invitation') {
        this.onSendInvitation()
      }
      if (command === 'copy_invite_link') {
        this.copyInvitationLink()
      }
    },
    copyInvitationLink: function () {
      const customers = this.GET_CUSTOMERS.results
      const codes = customers.filter(item => item.invitation_link && item.checked)
      if (codes.length === 0 && codes.length > 1) return;
      navigator.clipboard.writeText(codes[0].invitation_link)
      this.$toast.success({
        title: 'success',
        message: 'Invitation link copied successfully'
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_CUSTOMERS: 'customers/GET_CUSTOMERS'
    }),

  },
  mounted() {
    this.getCustomers()
  }
}
</script>

<style scoped>


</style>