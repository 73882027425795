<template>
  <div class="mt-2 w-full">
    <div class="block lg:flex lg:justify-between gap-2">
      <div class="w-full flex gap-4 justify-content-end py-3">
        <el-button class="float-end focus:border-none" type="primary" v-on:click="onAdd" icon="el-icon-plus" size="mini"
        >ADD SOURCE
        </el-button>
      </div>
    </div>
    <div class="w-full">
      <TableComponent :results="GET_METER_TARIFFS.results" :total_pages="1" :columns="columns"/>
    </div>
    <Modal v-if="onOpenTariffForm" :width="600"
           v-bind:title="currentTariff.code ? 'Update Meter Tariff' : 'Create Meter Tariff'"
           @handleClose="onOpenTariffForm = false">
      <div class="w-full" slot="body">
        <MeterTariffForm :tariff="currentTariff" @closeModal="onOpenTariffForm = false"
                         :meter_code="$route.params.code"/>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
  </div>
</template>

<script>
import * as _ from "lodash";
import TableComponent from "../../../../../components/Table"
import MeterTariffForm from "./MeterTariffForm";
import Modal from "../../../../../components/Modal";
import {errorFormat} from "../../../../../utils";
import {mapGetters} from "vuex";

export default {
  name: "SingleMeterSource",
  components: {TableComponent, Modal, MeterTariffForm},
  data: function () {
    return {
      columns: [
        {
          name: 'name',
          label: 'SOURCE',
          type: 'custom',
          custom: (row) => {
            if (!row.tariff) return "";
            return `${row.tariff.category}`
          }
        },
        {
          name: 'rate',
          label: 'RATE',
          type: 'custom',
          custom: (row) => {
            if (!row.tariff) return "";
            return `${row.tariff.rate}`
          }
        },
        {
          name: '',
          label: '',
          type: 'action',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          methodList: [
            {
              'icon': 'edit',
              css: 'bg-gray-200 w-24 py-[5px] px-2 text-black rounded text-[12px] font-semibold mr-1',
              'title': 'Edit',
              func: (item) => {
                this.onEdit(item)
              },
              enabled: () => {
                return true
              }
            },
            {
              'icon': 'delete',
              'title': 'Delete',
              css: 'bg-red-500 w-24 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onDelete(item)
              },
              enabled: () => {
                return true
              }
            },
          ],
        },
      ],
      onOpenTariffForm: false,
      currentTariff: {
        tariff_code: ''
      }
    }
  },
  methods: {
    onAdd: function () {
      this.currentTariff = {}
      this.onOpenTariffForm = true
    },
    onEdit: function (tariff) {
      const raw = JSON.parse(JSON.stringify(tariff))
      const tariff_code = _.get(raw, 'tariff.code', '')
      this.currentTariff = {
        ...raw,
        tariff_code
      }
      this.onOpenTariffForm = true
    },
    getMeterTariffs: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_tariff/getTariffs', {
        meter_code: this.$route.params.code,
        merchant: merchant
      }).then(() => {
        this.loading = false
      }).catch((err) => {
        this.loading = false
        errorFormat({error: err})
      })
    },
    onDelete: function (tariff) {
      const merchant = this.GET_USER.merchant.code
      this.$confirm('Do you want to delete this meter tariff source, This operation could result to deleting of all the resources attached to this meter tariff.', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('meter_tariff/deleteTariff', {
              merchant: merchant,
              code: tariff.code,
              meter_code: this.$route.params.code
            }).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'Meter tariff deleted successfully'
              })
            }).catch(er => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
  },
  mounted() {
    this.getMeterTariffs()
    window.Bus.$on("refresh-meter-tariff", () => {
      this.getMeterTariffs()
    })
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_METER_TARIFFS: 'meter_tariff/GET_TARIFFS'
    }),
  }
}
</script>

<style scoped>

</style>